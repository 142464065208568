import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { BiCloud, BiCog, BiLogOut, BiHome, BiEnvelope } from "react-icons/bi"; // Импортируем иконки из react-icons
import Logo from "./logo.svg";
import "./HeaderMenu.scss";

const HeaderMenu = ({ user, logout }) => {
  const location = useLocation();

  const menuItems = [
    {
      key: "/",
      icon: <BiHome />,
      label: <Link to="/">Главная</Link>,
    },
    {
      key: "sub1",
      icon: <BiCloud />,
      label: "Cloudflare",
      children: [
        {
          key: "/add-cloud",
          label: <Link to="/add-cloud">Добавить Cloudflare</Link>,
        },
        {
          key: "/dns-update",
          label: <Link to="/dns-update">Обновить DNS</Link>,
        },
        {
          key: "/purge",
          label: <Link to="/purge">Сбросить кэш</Link>,
        },
        {
          key: "/add-domain",
          label: <Link to="/add-domain">Добавить домен</Link>,
        },
        {
          key: "/add-back",
          label: <Link to="/add-back">Добавить бек</Link>,
        },
        {
          key: "/add-front",
          label: <Link to="/add-front">Добавить фронт + почтовые настройки</Link>,
        },
      ],
    },
    {
      key: "sub2",
      icon: <BiCog />,
      label: "Ссылки",
      children: [
        {
          key: "/links",
          label: <Link to="/links">Все ссылки</Link>,
        },
        {
          key: "/soon",
          label: <Link to="/">Скоро</Link>,
        },
      ],
    },
    {
      key: "/smtp",
      icon: <BiEnvelope />,
      label: <Link to="/smtp">SMTP Checker</Link>,
    },
    {
      key: "/logout",
      icon: <BiLogOut />,
      label: (
        <div onClick={logout}>
          Выйти
        </div>
      ),
    },
  ];

  const getDefaultSelectedKeys = () => {
    const path = location.pathname;
    const found = menuItems.find(item => item.key === path);
    if (found) {
      return [found.key];
    }
    for (let item of menuItems) {
      if (item.children) {
        const childFound = item.children.find(child => child.key === path);
        if (childFound) {
          return [childFound.key];
        }
      }
    }
    return ["/"];
  };

  return (
    <div className="header-menu">
      <img src={Logo} alt="Logo" className="logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={getDefaultSelectedKeys()}
        items={menuItems}
      />
    </div>
  );
};

export default HeaderMenu;
