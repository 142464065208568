import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const APIURL = 'https://api.aurora-connect.com/';
const apiCreds = process.env.REACT_APP_API_KEY;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { userData, expirationTime } = JSON.parse(storedUser);

      if (expirationTime && expirationTime > new Date().getTime()) {
        setUser(userData);
      } else {
        localStorage.removeItem('user');
      }
    }
  }, []);

  const login = async (username, password) => {
    try {
      const authString = `Basic ${btoa(apiCreds)}`;
      const response = await axios.post(
        `${APIURL}login`,
        { username, password },
        { headers: { Authorization: authString } }
      );

      if (response.data.success) {
        const loggedInUser = response.data.user;
        const expirationTime = new Date().getTime() + 3600 * 1000;
        localStorage.setItem('user', JSON.stringify({ userData: loggedInUser, expirationTime }));
        setUser(loggedInUser);
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem('user');
      setUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
