import React from "react";
import { Layout } from "antd";
import { useAuth } from "./Context/AuthContext";
import HeaderMenu from "./Components/HeaderMenu/HeaderMenu";
import PingResults from "./Components/PingResults/PingResults";
// import "antd/dist/antd.css"; // Import Ant Design styles

const { Header, Content } = Layout;

const Home = () => {
  const { user, logout } = useAuth();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header>
        <HeaderMenu user={user} logout={logout} />
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          {user ? (
            <PingResults />
          ) : (
            <h3>Авторизируйтесь</h3>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default Home;
