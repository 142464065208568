import React, { useState, useEffect } from 'react';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import './PingResults.scss';

function PingResults() {
  const [pingData, setPingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.aurora-connect.com/ping-results');
        if (!response.ok) {
          throw new Error(`Сервер ответил со статусом ${response.status}`);
        }

        const result = await response.json();

        if (result && result.monitors) {
          // Сортируем данные так, чтобы все элементы с uptime_status === 'down' шли первыми
          const sortedData = result.monitors.sort((a, b) => {
            if (a.uptime_status === 'down' && b.uptime_status !== 'down') {
              return -1;
            }
            if (a.uptime_status !== 'down' && b.uptime_status === 'down') {
              return 1;
            }
            return 0;
          });

          setPingData(sortedData);
        } else {
          throw new Error('Непредвиденный формат ответа');
        }
      } catch (error) {
        console.error('Ошибка при получении данных:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Результаты Ping</h1>
      {isLoading ? (
        <div className="skeleton-container">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="skeleton-row"></div>
          ))}
        </div>
      ) : (
        <table className="table table-hover">
          <thead className='thead-dark'>
            <tr>
              <th>Имя</th>
              <th>Ссылка</th>
              <th>Статус</th>
              <th>Последняя проверка</th>
            </tr>
          </thead>
          <tbody>
            {pingData.map((monitor) => (
              <tr
                key={monitor.id}
                className={monitor.uptime_status === 'down' ? 'down-row red-background' : ''}
              >
                <td>{monitor.name}</td>
                <td><a href={monitor.target} target='blank'>{monitor.target}</a></td>
                <td>
                  {monitor.uptime_status === 'up' ? (
                    <IoIosArrowUp className="text-success" />
                  ) : (
                    <IoIosArrowDown className="text-danger" />
                  )}
                  {monitor.uptime_status}
                </td>
                <td>{new Date(monitor.last_check * 1000).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default PingResults;