import React, { useState, useEffect } from 'react';
import { useAuth } from "../../Context/AuthContext";
import { Layout, Select, Button, message } from 'antd';
import HeaderMenu from '../HeaderMenu/HeaderMenu';

const { Header, Content } = Layout;
const { Option } = Select;

const apiCreds = process.env.REACT_APP_API_KEY;

const MailServerChecker = () => {
  const [servers, setServers] = useState([]);
  const [selectedServer, setSelectedServer] = useState('');
  const [result, setResult] = useState(null);
  const { user, logout } = useAuth();

  useEffect(() => {
    fetch('https://api.aurora-connect.com/mail-servers', {
      headers: {
        Authorization: `Basic ${btoa(apiCreds)}`,
      },
    })
      .then(response => response.json())
      .then(data => setServers(data))
      .catch(error => console.error('Error fetching mail servers:', error));
  }, []);

  const handleServerChange = value => {
    setSelectedServer(value);
  };

  const checkSMTPServer = async () => {
    const selected = servers.find(server => server.name === selectedServer);
    if (!selected) {
      console.error('Selected server not found');
      return;
    }

    try {
      const response = await fetch('https://api.aurora-connect.com/check-smtp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(apiCreds)}`,
        },
        body: JSON.stringify(selected),
      });

      const data = await response.json();
      setResult(data);
      message.success(data.message);
    } catch (error) {
      console.error('Error checking SMTP server:', error);
      message.error('Error checking SMTP server.');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
            <Header>
        <HeaderMenu user={user} logout={logout} />
      </Header>
      <Content style={{ padding: '0 50px', marginTop: '64px' }}>
        <div className="site-layout-content">
          {user ? (
            <>
              <h1>Check SMTP Server</h1>
              <label>Select Mail Server:</label>
              <Select
                value={selectedServer}
                onChange={handleServerChange}
                style={{ width: 200 }}
                placeholder="Select..."
              >
                {servers.map(server => (
                  <Option key={server.name} value={server.name}>
                    {server.name}
                  </Option>
                ))}
              </Select>
              <Button type="primary" onClick={checkSMTPServer} style={{ marginLeft: '8px' }}>
                Check SMTP Server
              </Button>
              {result && (
                <div className={result.success ? 'success' : 'error'}>
                  <p>{result.message}</p>
                  {result.success && (
                    <p>Server Info: {JSON.stringify(result.info)}</p>
                  )}
                </div>
              )}
            </>
          ) : (
            message.warning('Please log in to check SMTP servers.')
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default MailServerChecker;
